"use client";

import React from "react";
import ThemeUIProvider from "@/components/themeUIProvider";
import Chip from "@mui/material/Chip";

const MUIChip = (props) => {
  const { children, className, ...rest } = props;
  return (
    <ThemeUIProvider>
      <Chip {...props} />
    </ThemeUIProvider>
  );
};

export default MUIChip;
