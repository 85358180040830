'use client';
import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

const Bedrooms = props => {
  const { onChange, defaultValue = [], ...params } = props;
  const [value, setValue] = useState(defaultValue.map(item => Number(item)));
  const bedrooms = [];
  for (let i = 0; i < 10; i++) {
    bedrooms.push({ value: i, label: i > 0 ? i : 'Studio' });
  }
  const setFieldValue = value => {
    setValue(value);
    onChange({ bedrooms: value });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id='filter-bedrooms'>Bedrooms</InputLabel>
      <Select
        labelId='filter-bedrooms'
        id='filter-bedrooms'
        value={value}
        label='Bedrooms'
        multiple
        onChange={e => {
          const value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
          setFieldValue(value);
        }}
        MenuProps={MenuProps}
      >
        {bedrooms.map((item, index) => (
          <MenuItem value={item.value} key={index}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Bedrooms;
