"use client";

import TextField from "@mui/material/TextField";
import { useDeferredValue, useEffect, useRef, useState } from "react";
import getLocations from "@/data/getLocations";
import { ClickAwayListener, Popper } from "@mui/material";

const locationIdentifiers = {
  1: "state_id",
  2: "city_id",
  3: "district_id",
  4: "community_id",
  5: "sub_community_id",
};

export default function SearchLocation({
  onChange,
  defaultValue = "",
  helperText = "",
  value = "",
}) {
  const [hide, setHide] = useState(true);
  const [search, setSearch] = useState(defaultValue);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const selected = useRef(false);
  const input = useRef(null);
  const query = useDeferredValue(search);

  useEffect(() => {
    if (selected.current) return setOptions([]);

    if (query) {
      setLoading(true);
      getLocations(query)
        .then(setOptions)
        .finally(() => setLoading(false));
    }

    if (!query) setOptions([]);
  }, [query]);

  useEffect(() => {
    setSearch(value || defaultValue);
  }, [value]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setHide(true);
      }}
    >
      <div className="relative">
        <TextField
          label="Location"
          error={!!helperText}
          helperText={helperText}
          className="w-full"
          value={search}
          type="search"
          autoComplete="off"
          ref={input}
          onChange={(event) => {
            selected.current = false;
            setHide(false);
            setSearch(event.target.value);
            onChange({
              fullName: "",
              country_id: "",
              state_id: "",
              city_id: "",
              district_id: "",
              community_id: "",
              sub_community_id: "",
            });
          }}
          InputProps={{
            className: "bg-white",
          }}
        />

        <Popper
          open={!!options?.length && search && !hide}
          anchorEl={input.current}
          onClose={() => setHide(true)}
          slotProps={{
            root: {
              className: "z-10 w-inherit",
            },
          }}
        >
          <div className="w-full p-2 bg-white translate-y-0.5 z-10 shadow-md rounded-b max-h-[min(30vmax,300px)] overflow-y-auto">
            <ul>
              {options.map((location, index) => (
                <li
                  key={index}
                  className="text-gray-600 text-sm py-1 cursor-pointer hover:bg-black/5 px-2 rounded transition select-none"
                  onClick={() => {
                    if (!onChange) return;

                    const newValue = location;

                    setSearch(newValue.fullName);
                    setHide(true);

                    if (newValue) {
                      const { crmId, level } = newValue;
                      const key = locationIdentifiers[level];
                      return onChange({
                        fullName: newValue.fullName,
                        [key]: crmId,
                      });
                    }

                    onChange({
                      fullName: "",
                      country_id: "",
                      state_id: "",
                      city_id: "",
                      district_id: "",
                      community_id: "",
                      sub_community_id: "",
                    });
                  }}
                >
                  {location.fullName}
                </li>
              ))}
            </ul>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
