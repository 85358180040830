"use client";

import { useRef, useState, useTransition } from "react";
import { useRouter } from "next/navigation";
import PriceRange from "@/components/fields/PriceRange";
import Bedrooms from "@/components/fields/Bedrooms";
import Button from "@/components/Button";
import Purpose from "@/components/fields/Purpose";
import ThemeUIProvider from "@/components/themeUIProvider";
import SearchLocation from "@/components/fields/SearchLocation";

const RentFilterForm = () => {
  const formRef = useRef(null);
  const [values, setValues] = useState({ purpose: "rent", city: 2 });
  const router = useRouter();
  const [isPending, startTransition] = useTransition();

  async function onSubmit(event) {
    event.preventDefault();
    const qs = await import("qs");
    const { purpose, ...params } = values;
    const queryString = qs.stringify(params, { indices: false });
    const url = `/${purpose}?${queryString}`;
    startTransition(() => {
      router.push(url);
    });
  }

  const handleFieldChange = (field) => {
    setValues({ ...values, ...field });
  };

  return (
    <ThemeUIProvider>
      <form onSubmit={onSubmit} ref={formRef}>
        <div className="flex flex-col gap-1 ">
          <Purpose
            onChange={handleFieldChange}
            defaultValue={values.purpose}
            variant="tab"
          />
          <div className="tab-panels flex flex-col lg:flex-row bg-white p-4 lg:p-6 gap-4 lg:gap-4 rounded min-w-[48rem] items-center">
            <div className="min-w-64 flex-1 w-full">
              <SearchLocation onChange={handleFieldChange} />
            </div>
            <div className="min-w-32 flex-1 w-full">
              <Bedrooms onChange={handleFieldChange} />
            </div>
            <div className="min-w-40 w-full flex-1">
              <PriceRange onChange={handleFieldChange} />
            </div>
            <Button
              variant="contained"
              type="submit"
              disabled={isPending}
              className="min-w-32 w-full lg:w-fit"
            >
              {isPending ? "Submitting" : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </ThemeUIProvider>
  );
};

export default RentFilterForm;
