'use client'
import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@/components/Button";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};


const Purpose = (props) => {
    const { onChange, defaultValue, variant } = props;
    const [value, setValue] = useState(defaultValue || 0)
    const handleFieldChange = (value) => {
        setValue(value);
        onChange({ 'purpose': value })
    };
    if (variant == "tab" ) {
        return(
            <div className="tabs flex justify-center gap-4 pb-3">
                <Button className={`tab ${value === "rent" ? "active" : "white"}`} onClick={() => handleFieldChange('rent')} type="button">Rent</Button>
                <Button className={`tab ${value === "buy" ? "active" : "white"}`} onClick={() => handleFieldChange('buy')} type="button">Buy</Button>
            </div>
        )
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="filter-purpose">Purpose</InputLabel>
            <Select
                labelId="filter-purpose"
                value={value || ""}
                label="Purpose"
                onChange={(e) => {
                    handleFieldChange(e.target.value);
                }}
                MenuProps={MenuProps}
            >
                <MenuItem value="rent">Rent</MenuItem>
                <MenuItem value="buy">Buy</MenuItem>
            </Select>
        </FormControl>
    )
};

export default Purpose;
